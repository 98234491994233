import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from '../pages/footer/Footer';
import VerifyEmail from '../pages/verifyEmail/verifyEmail.page';
import ResetPassword from '../pages/resetPassword/resetPassword.page';
import HomePage from '../pages/homePage/home.page';
import DeleteAccountGuide from '../pages/deleteAccount/DeleteAccountGuide';

function App() {

  return (
    <div className="body-container">
      <Routes>
        <Route Component={HomePage} path="/" />
        <Route Component={VerifyEmail} path="/confirm-email/:token" />
        <Route Component={ResetPassword} path="/reset-password/:token" />
        <Route Component={DeleteAccountGuide} path="/delete-account-guide" />
      </Routes>
      <Footer/>
    </div>
  );
 
}

export default App;
