import React, { useState } from 'react';
import './DeleteAccountGuide.css';
import Navbar from '../navBar/Navbar';
import { apiClient } from '../../services/APIClient';

const DeleteAccountGuide: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setIsSubmitted(false);
            const response = await apiClient.post('/users/request-deletion', { email });
            if (response.status === 404) {
                alert('User not found');
                return;
            }
            setIsSubmitted(true);
            setEmail('');
        } catch (error) {
            console.error('Error submitting delete request:', error);
            // Handle error (you might want to show an error message to the user)
        }
    };

    return (
        <div className='content-for-user-action'>
            <Navbar />
            <div className="delete-account-guide">
                <h1>Delete Your Account</h1>
                <div className="privacy-information">
                    <h2>What Happens When You Delete Your Account</h2>
                    <p>
                        At Darb, we respect your right to privacy and are committed to ensuring your data is handled responsibly. 
                        When you request to delete your account:
                    </p>
                    <ul>
                        <li>All personal information will be removed, including:
                            <ul>
                                <li>Name</li>
                                <li>Profile image</li>
                                <li>Phone number</li>
                                <li>Emergency contact details</li>
                                <li>Other personally identifiable information (PII)</li>
                            </ul>
                        </li>
                        <li>Your reviews will remain visible but will be marked as "Anonymous"</li>
                        <li>Created hikes will remain but will no longer be linked to your profile</li>
                        <li>Data will be permanently deleted within 90 days of confirmation</li>
                    </ul>
                    <p>If you have further questions, please contact our support team for assistance.</p>
                </div>

                <div className="warning-note">
                    <p><strong>Note:</strong> This action cannot be undone. All your data will be permanently deleted.</p>
                </div>
                
                <form onSubmit={handleSubmit} className="delete-account-form">
                    <div className="form-group">
                        <label htmlFor="email">Confirm your email address:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter your email"
                        />
                    </div>
                    <button type="submit">Request Account Deletion</button>
                </form>

                {isSubmitted && (
                    <div className="success-message">
                        <p>Your account deletion request has been submitted. We will let you know when your account has been deleted.</p>
                    </div>
                )}
            </div>
        </div>
        
    );
};

export default DeleteAccountGuide;